import React, { useState, useEffect } from 'react';

const SpiderPointer = ({ numLegs = 3 }) => {
  // ... (SpiderPointer component remains the same)
};

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
      isScrolled 
        ? 'bg-opacity-70 backdrop-blur-md py-2' 
        : 'bg-opacity-100 py-3'
    } bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-500 text-white`}>
      <SpiderPointer />
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <h1 className={`font-bold transition-all duration-300 ${
              isScrolled ? 'text-xl' : 'text-2xl'
            }`}>SouthNetSys</h1>
            <p className={`hidden md:block transition-all duration-300 ${
              isScrolled ? 'text-xs' : 'text-sm'
            }`}>Your Tech Solutions Partner</p>
          </div>
          <nav>
            <ul className="flex space-x-2 md:space-x-4">
              <li>
                <a href="#services" className={`hover:text-pink-200 transition-colors ${
                  isScrolled ? 'text-xs md:text-sm' : 'text-sm md:text-base'
                }`}>Services</a>
              </li>
              <li>
                <a href="#about" className={`hover:text-pink-200 transition-colors ${
                  isScrolled ? 'text-xs md:text-sm' : 'text-sm md:text-base'
                }`}>About</a>
              </li>
              <li>
                <a href="#contact" className={`bg-white text-indigo-600 rounded-full hover:bg-opacity-90 transition-colors ${
                  isScrolled 
                    ? 'text-xs md:text-sm py-1 px-2 md:px-3' 
                    : 'text-sm md:text-base py-1 px-3'
                }`}>Contact</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;