import React, { useState } from 'react';
import { HiMail, HiPhone, HiLocationMarker } from 'react-icons/hi';
import { FaFacebookF } from 'react-icons/fa';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const validateForm = () => {
    let tempErrors = {};
    if (!formData.name.trim()) tempErrors.name = "Name is required";
    if (!formData.email.trim()) {
      tempErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = "Email is invalid";
    }
    if (!formData.subject.trim()) tempErrors.subject = "Subject is required";
    if (!formData.message.trim()) tempErrors.message = "Message is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log("Form submitted:", formData);
      setFormData({ name: '', email: '', subject: '', message: '' });
      setErrors({});
    }
  };

  return (
    <section id="contact" className="py-20 bg-gradient-to-br from-gray-900 to-blue-900">
      <div className="container mx-auto px-4 md:px-8">
        <div className="max-w-6xl mx-auto bg-white rounded-3xl shadow-2xl overflow-hidden">
          <div className="flex flex-col lg:flex-row">
            {/* Left side - Contact Info */}
            <div className="lg:w-2/5 bg-gradient-to-br from-blue-600 to-purple-600 p-10 text-white relative">
              <div className="absolute top-0 left-0 w-full h-full bg-blue-600 opacity-10 pattern-diagonal-lines-sm"></div>
              <div className="relative z-10">
                <h2 className="text-4xl font-bold mb-6">Get in Touch</h2>
                <p className="mb-10 text-blue-100 text-lg">
                  Ready to elevate your tech game? Reach out to our team of experts for all your IT needs.
                </p>
                <div className="space-y-8">
                  <div className="flex items-center space-x-4 group">
                    <div className="bg-white p-2 rounded-full text-blue-600 group-hover:bg-blue-100 transition-colors">
                      <HiMail className="text-2xl" />
                    </div>
                    <a href="mailto:info@southnetsys.com" className="text-lg group-hover:underline">info@southnetsys.com</a>
                  </div>
                  <div className="flex items-center space-x-4 group">
                    <div className="bg-white p-2 rounded-full text-blue-600 group-hover:bg-blue-100 transition-colors">
                      <HiPhone className="text-2xl" />
                    </div>
                    <a href="tel:+94777739835" className="text-lg group-hover:underline">+94 777739835</a>
                  </div>
                  <div className="flex items-center space-x-4 group">
                    <div className="bg-white p-2 rounded-full text-blue-600 group-hover:bg-blue-100 transition-colors">
                      <HiLocationMarker className="text-2xl" />
                    </div>
                    <p className="text-lg">Ethkandura</p>
                  </div>
                </div>
                <div className="mt-12">
                  <a href="https://www.facebook.com/profile.php?id=100059080036059" 
                     className="inline-flex items-center space-x-2 bg-white text-blue-600 px-6 py-3 rounded-full hover:bg-blue-50 transition-colors"
                     target="_blank" rel="noopener noreferrer">
                    <FaFacebookF className="text-xl" />
                    <span className="font-semibold">Follow us on Facebook</span>
                  </a>
                </div>
              </div>
            </div>
            
            {/* Right side - Contact Form and Logo */}
            <div className="lg:w-3/5 p-10">
              <div className="flex justify-between items-start mb-10">
                <h3 className="text-3xl font-bold text-gray-800">Send us a message</h3>
                {/* Company Logo */}
                <img src="/images/services/southnetsys.jpg" alt="SouthNetSys Logo" className="w-32 h-auto" />
                
              </div>
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="flex space-x-4">
                  <div className="flex-1">
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Name <span className="text-red-500">*</span></label>
                    <input 
                      type="text" 
                      id="name" 
                      name="name" 
                      value={formData.name}
                      onChange={handleChange}
                      className={`w-full px-4 py-2 border ${errors.name ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`} 
                    />
                    {errors.name && <p className="mt-1 text-xs text-red-500">{errors.name}</p>}
                  </div>
                  <div className="flex-1">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email <span className="text-red-500">*</span></label>
                    <input 
                      type="email" 
                      id="email" 
                      name="email" 
                      value={formData.email}
                      onChange={handleChange}
                      className={`w-full px-4 py-2 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`} 
                    />
                    {errors.email && <p className="mt-1 text-xs text-red-500">{errors.email}</p>}
                  </div>
                </div>
                <div>
                  <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">Subject</label>
                  <input 
                    type="text" 
                    id="subject" 
                    name="subject" 
                    value={formData.subject}
                    onChange={handleChange}
                    className={`w-full px-4 py-2 border ${errors.subject ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`} 
                  />
                  {errors.subject && <p className="mt-1 text-xs text-red-500">{errors.subject}</p>}
                </div>
                <div>
                  <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">Message <span className="text-red-500">*</span></label>
                  <textarea 
                    id="message" 
                    name="message" 
                    rows="4" 
                    value={formData.message}
                    onChange={handleChange}
                    className={`w-full px-4 py-2 border ${errors.message ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
                  ></textarea>
                  {errors.message && <p className="mt-1 text-xs text-red-500">{errors.message}</p>}
                </div>
                <button type="submit" className="w-full bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 text-lg font-semibold">
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
