import React from 'react';

const Services = () => {
  return (
    <section id="services" className="bg-gray-100 py-16">
      <div className="container mx-auto text-center px-4">
        <h2 className="text-4xl font-bold mb-6">Our Services</h2>
        <p className="text-lg mb-12 max-w-2xl mx-auto">
          We pride ourselves on our wide range of services tailored to cater to various tech requirements.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          <div className="service bg-white p-8 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300">
            <img src="/images/services/laptop.jpg" alt="Computer & Laptop Repairs" className="w-full h-48 object-cover mb-4 rounded-lg" />
            <h3 className="text-2xl font-semibold mb-2">Computer & Laptop Repairs</h3>
            <p className="text-gray-600">From troubleshooting software issues to hardware replacements, our skilled technicians handle it all.</p>
          </div>
          <div className="service bg-white p-8 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300">
            <img src="/images/services/computersalesrepairs.jpg" alt="Sales of Computers, Laptops & Accessories" className="w-full h-48 object-cover mb-4 rounded-lg" />
            <h3 className="text-2xl font-semibold mb-2">Sales of Computers, Laptops & Accessories</h3>
            <p className="text-gray-600">Offering the latest models and essential peripherals to keep you up-to-date with technology.</p>
          </div>
          <div className="service bg-white p-8 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300">
            <img src="/images/services/ACCC.jpg" alt="Mobile Phones & Accessories" className="w-full h-48 object-cover mb-4 rounded-lg" />
            <h3 className="text-2xl font-semibold mb-2">Mobile Phones & Accessories</h3>
            <p className="text-gray-600">A vast selection of mobile phones and accessories to suit your lifestyle.</p>
          </div>
          <div className="service bg-white p-8 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300">
            <img src="/images/services/Web.jpg" alt="Website & System Design" className="w-full h-48 object-cover mb-4 rounded-lg" />
            <h3 className="text-2xl font-semibold mb-2">Website & System Design</h3>
            <p className="text-gray-600">Custom-designed websites and systems that enhance your business presence online.</p>
          </div>
          <div className="service bg-white p-8 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300">
            <img src="/images/services/Consulting.jpg" alt="IT Consultations" className="w-full h-48 object-cover mb-4 rounded-lg" />
            <h3 className="text-2xl font-semibold mb-2">IT Consultations</h3>
            <p className="text-gray-600">Expert advice and solutions for all your IT-related challenges.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
